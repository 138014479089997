<template>
  <div class="dashboard workout-by-page">
    <tabs/>
    <div class="box-tab-content">
      <div class="workout-slider-block" v-for="(item,index) in areaItems" :key="index">
        <workoutBySlider :sliderItem="item"/>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import workoutBySlider from "@/components/workouts/workout-by-slider.vue";
import tabs from "@/components/workouts/workout-by-tabs.vue";
import axios from "axios";

export default {
  name: "workout-by",
  data () {
    return {
      lang: this.$root.siteLang,
      areaItems: [],
    }
  },
  metaInfo () {
    return {
      title: this.$root.translations.treinar_por,
    }
  },
  components : {
    workoutBySlider,
    tabs
  },
  created() {
    this.$root.pageTitle = this.$root.translations.treinar_por;

    axios
        .get(process.env.VUE_APP_API_URL + 'areas/12/items?type=cp&with_category_title=0&with_products=1&lang=' + this.lang)
        .then((response) => {
          this.areaItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>